/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import { Grid, RACButton, RACToggle, Typography } from "@rentacenter/racstrap";
import { globalStyles } from './../../jsStyles/globalStyles';
import { updateArrayOfObjectState, updateObjectStateVariable } from './Utils';
import { DynamicVerification } from '../../interface/globalInterface';
import { ReactComponent as SuccessIcon } from '../../assets/images/Completed-icon.svg';
import { ReactComponent as PendingIcon } from '../../assets/images/Pending-icon.svg';
import { ReactComponent as BlueInfoIcon } from '../../../assets/images/blue-info-icon.svg';
import { ReactComponent as FailedIcon } from '../../assets/images/Failed-icon.svg';

import { UpdateCustomerContext } from '../../context/UpdateCustomerContext';

export const VerificationComponent = (props: { value: DynamicVerification }) => {
  const { isButtonEnabled, editEnabled, validateInputFields, actualVerifiedValue, updatedVerifiedValue, setterFunction, isArrayOfObject, index, stateVariable, IdentityIntellicheckVerification } = props.value;
  const { configurations, setSendIdentityVerificationTextOpen, timeLeft, setTimeLeft, isTimerActive, setIsTimerActive, intellicheckStatus, setIntellicheckStatus, intellicheckmismatchedValues } = useContext(UpdateCustomerContext);
  const IntellicheckURL = configurations.intellicheck;
  const classes = globalStyles();
  // eslint-disable-next-line no-console
  console.log('Inside the verificationComponent', props.value);


  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  useEffect(() => {
    setIntellicheckStatus(intellicheckStatus)
  }, [intellicheckStatus])
  const intellicheckResField = () => {
    switch (intellicheckStatus) {
      case 'Passed':
        return <><SuccessIcon style={{ marginTop: '7px', marginBottom: '-6px' }} /> <span style={{ fontFamily: 'OpenSans-bold' }}>Passed</span></>;
      case 'Failed':
        return <>< FailedIcon style={{ marginTop: '9px', marginBottom: '-5px' }} />  <span style={{ fontFamily: 'OpenSans-bold' }}>{'Failed' + `${intellicheckmismatchedValues ? ' - ' : ''}` + intellicheckmismatchedValues + ' ' + `${intellicheckmismatchedValues ? 'Mismatch' : ''}`}</span> </>;
      case 'PassedWithFlag':
        return <>< PendingIcon style={{ height: '28px', marginBottom: '-8px', width: '20px' }} /> <span style={{ fontFamily: 'OpenSans-bold' }}>{'Passed' + ' - ' + intellicheckmismatchedValues + ' ' + 'Mismatch'}</span> </>;
      default:
        return null;
    }
  }
  return (
    <Grid container className={classes.width100p}>
      <Grid container md={12} className={`${classes.mt20px}`}>
        <Grid item md={12}>
          <Typography
            className={`${classes.fontSize16px} ${classes.fontOpensansBold}`}
          >
            {IdentityIntellicheckVerification ? 'Intellicheck Verification' : 'Verification'}
          </Typography>

          {IdentityIntellicheckVerification ? (
            <Typography>
              <span>{intellicheckResField()}</span>
            </Typography>
          ) : null}

          {isTimerActive ? <Typography><BlueInfoIcon style={{ width: '15px', height: '28px', marginBottom: '-9px' }} />Customer has {formatTime(timeLeft)} minutes to complete verification. Results will be updated on this screen once available.</Typography> : null}
          {!IdentityIntellicheckVerification ? <Typography
            className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold} ${classes.lightGreyColor} ${classes.mt5px}`}
          >
            Please enter all required fields to enable Verification
          </Typography> : null}

        </Grid>
      </Grid>
      <Grid container md={12} className={`${classes.mt20px}`}>
        {isButtonEnabled ? (
          <Grid item md={IdentityIntellicheckVerification ? 4 : 2} lg={2} xs={6}>
            <RACButton
              variant="outlined"
              color="primary"
              disabled={false}
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => IdentityIntellicheckVerification ? setSendIdentityVerificationTextOpen(true) : window.open(IntellicheckURL)}
            >
              {IdentityIntellicheckVerification ? 'Send Identity Verification Text' : 'Launch Intellicheck'}
            </RACButton>
          </Grid>
        ) : null}
        <Grid item md={3} lg={3} xs={6} style={{ marginLeft: IdentityIntellicheckVerification ? '60px' : '0px' }}>
          <RACToggle
            rightText={true}
            rightLabel={`Verified`}
            labelClassName={`${classes.ml10px}`}
            toggleColor="#0d6efd"
            GridClassName={`${classes.displayFlex} ${classes.mt10px}`}
            disabled={!editEnabled || !validateInputFields}
            checked={
              !editEnabled
                ? actualVerifiedValue.verified
                : updatedVerifiedValue.verified
            }
            OnChange={(e) => {
              if (isArrayOfObject && stateVariable) {
                updateArrayOfObjectState(
                  stateVariable,
                  setterFunction,
                  index,
                  { verified: e.target.checked }
                )
              } else {
                updateObjectStateVariable(
                  setterFunction,
                  "verified",
                  e.target.checked
                )
              }
            }}
          />
        </Grid>
      </Grid>
    </Grid >
  );
};