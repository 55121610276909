/* eslint-disable */
/* eslint-disable prettier/prettier */

import React, { useContext, useEffect, useRef, useState } from "react";

import {
  Grid,
  RACButton,
  RACModalCard,
  RACSelect,
  RACTextbox,
  Typography,
  CircularProgress
} from "@rentacenter/racstrap";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  DropdownOptions,
  DynamicVerification,
  EmployerDetails,
  IdentityDetailsInfo,
  IdentityDetailsProps,
  ParamType,
  ReferenceDetail,
  ResidenceInfo,
} from "../../../interface/globalInterface";
import { globalStyles } from "../../../jsStyles/globalStyles";
import {
  isNotNullVariable,
  updateCustomerAndVerification,
  updateObjectStateVariable,
  updateObjectWithMultipleKeys,
} from "../Utils";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { VerificationComponent } from "./../VerifiedComponent";
import { useParams } from "react-router-dom";
import SecondFactor from "../../../../racstrapComponent/manageCustomer/secondAuthen";
import { DynamicVerifiedStatus } from "../DynamicVerifiedStatus";
import crypto, { createDecipheriv } from "crypto";

import moment from "moment";
import jsPDF from 'jspdf';
import { GOVT_ID_TYPE_CVO, MODULE_NAME } from "../../../constants/constants";
import { getMaskedValue } from "../../../../utils/utils";
import { FeatureFlagContext } from "../../../../context/FeatureFlagContext";
import { ReactComponent as PDFImage } from '../../../../assets/images/pdf-icon.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/images/success-icon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/Warning-icon.svg';
import { ReactComponent as ContactIcon } from '../../../assets/images/ContactIcon.svg';
import { ReactComponent as ViewEyeIcon } from '../../../assets/images/ViewPdfIcon.svg';


import { SendIntellicheck, getIntellicheckStatus, getVerificationDocuments, uploadVerificationDocuments, GetCustomer } from "../../../api/user";

import { UpdateVerificationOptimization, UpdateCustomerDetails } from "../../../../api/user";
import { formatPhone } from "../../../utils/utils";

import { decryptData } from "../../../api/user";
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function IdentityDetails(props: {
  value: IdentityDetailsProps;
}) {
  let {
    govtIDTypeOptions,
    stateOptions,
    setGetCustomerInfoResponse,
    setGetApprovalRes,
    getApprovalRes,
    showDecryptedValues,
    currentRole,
    coworkerIds,
    currentEmpId,
    editEnabled,
    setEditEnabled,
    sendIdentityVerificationTextOpen,
    setSendIdentityVerificationTextOpen,
    customerBasicInformation,
    phoneTypeOptions,
    updatedContactInfo,
    timeLeft, setTimeLeft,
    isTimerActive, setIsTimerActive,
    intellicheckStatus, setIntellicheckStatus,
    intellicheckmismatchedValues, setIntellicheckmismatchedValues,
    screenLoader, setScreenLoader
  } = useContext(UpdateCustomerContext);
  const { featureFlagDetails } = useContext(FeatureFlagContext);


  const modalCardRadius = '25px !important';
  let {
    identityDetails,
    setIdentityDetails,
    errorMessage,
    actualIdentityDetails,
    setActualIdentityDetials,
    setIdentityErrorMessage,
  } = props.value;
  let timer: any = null
  const { customerId } = useParams<ParamType>();
  const [secondLevelAuth, setSecondLevelAuth] = useState<boolean>(false);
  const [successfullPopup, setSuccessfullPopup] = useState<boolean>(false)
  const [somethingWentWrongOpen, setSomethingWentWrongOpen] = useState<boolean>(false)
  const [errorMessageText, seterrorMessageText] = useState<string>('')

  const [customerIdentityDocuments, setCustomerIdentityDocuments] = useState<any>();
  const [identityDocsecondLevelAuth, setidentityDocsecondLevelAuth] = useState<boolean>(false)
  const [idDocBse64, setIdDocBse64] = useState<string>('')
  const [idDocUrl, setidDocUrl] = useState<string>('')



  const [intellicheckPhoneNumber, setIntellicheckPhoneNumber] = useState<string>('')
  const mandatoryFieldsCheck: boolean =
    (identityDetails.governmentId !== "" ||
      identityDetails.governmentIdType !== "" &&
      identityDetails.governmentIdType === "NONE" ||
      identityDetails.issuedState !== "");
  const validateInputFields: boolean = (
    identityDetails.governmentId !== '' &&
    identityDetails.governmentIdType !== '' &&
    identityDetails.issuedState !== ''
  )
  const classes = globalStyles();
  const [isGovtIdClicked, setGovIdClicked] = useState<boolean>(true);


  const encryptKey = crypto.randomBytes(16).toString("hex");
  const ivKey = crypto.randomBytes(8).toString("hex");

  let startTimeRef = useRef<number | null>(null); // To track the start time
  let timeRemainingRef = useRef<number>(0); // To store remaining time across renders
  let intervalRef = useRef<any>(null); // To store the interval ID


  const verificationTabPayload: DynamicVerification = {
    isButtonEnabled: featureFlagDetails?.LaunchIntellicheck == '1' ? true : false,
    IdentityIntellicheckVerification: featureFlagDetails?.IdentityIntellicheckVerification == '1' ? true : false,
    validateInputFields,
    editEnabled,
    actualVerifiedValue: actualIdentityDetails,
    updatedVerifiedValue: identityDetails,
    setterFunction: setIdentityDetails as React.Dispatch<
      React.SetStateAction<
        IdentityDetailsInfo | ResidenceInfo | ReferenceDetail | EmployerDetails
      >
    >,
    intellicheckStatus: intellicheckStatus,
    intellicheckmismatchedValues: intellicheckmismatchedValues
  };

  // This useEffect is to ensure that the initial time from featureFlagDetails is set correctly
  useEffect(() => {
    const initialTime = Number(featureFlagDetails?.IntellicheckTimer) * 60 - 1;
    setTimeLeft(initialTime);
    timeRemainingRef.current = initialTime;
  }, [featureFlagDetails]); // Ensure this runs when featureFlagDetails changes
  useEffect(() => {
    console.log("identdrityDetailss", identityDetails)
    setIsTimerActive(false)
    const customerArray: any = window.sessionStorage.getItem('IntellicheckFlag')
    let customerPresent: boolean = false
    const initalLoad = async () => {

      if (customerArray) {
        const currentSessionValue: any = JSON.parse(customerArray);
        customerPresent = currentSessionValue.includes(customerId)
      }

      console.log("getApprovalRes11112", getApprovalRes)
      if (getApprovalRes && getApprovalRes?.verificationDocuments) {
        setScreenLoader(true)

        const IdentiyDocument = getApprovalRes?.verificationDocuments &&
          getApprovalRes?.verificationDocuments?.find(
            (element: any) => element.verifyType == "ID"
          )
        const updatedDetails: any = {
          ...identityDetails,
          verifiedSource: IdentiyDocument?.lastModifiedBy == 'Intellicheck' ? IdentiyDocument?.lastModifiedBy : IdentiyDocument?.clientId,
          verifiedDate: IdentiyDocument?.lastModifiedDate ? IdentiyDocument?.lastModifiedDate : moment().format('MM/DD/YYYY'),
          verificationStatus: IdentiyDocument?.verificationStatus ? IdentiyDocument?.verificationStatus : null
        };
        actualIdentityDetails = updatedDetails
        setActualIdentityDetials(actualIdentityDetails);
        if (updatedContactInfo && updatedContactInfo.length > 0) {
          const phoneNumber = updatedContactInfo?.filter((obj: any) => obj.primary == 'Y' && obj.active == 'Y')
          phoneNumber.length > 0 ? setIntellicheckPhoneNumber(formatPhone(phoneNumber[0]?.phoneNumber)) : setIntellicheckPhoneNumber('')
        }
      }
      if (featureFlagDetails?.IdentityIntellicheckVerification == '1' && actualIdentityDetails.decisionEngineId) {
        setScreenLoader(true)
        await getVerificationDocumentsFn()

        console.log("govtIDTypeOptionsgovtIDTypeOptions", govtIDTypeOptions)
        if (customerPresent) {
          await getIntellicheckStatusFn()
        }

      }
      else {
        setScreenLoader(false)

      }
    }

    initalLoad()
    // Cleanup the interval when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [])

  // Function to start the timer
  const handleStartTimer = () => {
    if (!isTimerActive) {
      setIsTimerActive(true);
      startTimeRef.current = performance.now(); // Set the start time using performance.now()
      timeRemainingRef.current = timeLeft; // Set remaining time
      intervalRef.current = setInterval(updateTimer, 1000); // Update the timer every second
    }
  };

  // Update the timer every second
  const updateTimer = () => {
    if (startTimeRef.current) {
      const elapsedTime = Math.floor((performance.now() - startTimeRef.current) / 1000); // Calculate elapsed time in seconds
      const remainingTime = timeRemainingRef.current - elapsedTime;

      if (remainingTime <= 0) {
        clearInterval(intervalRef.current!); // Stop the timer when it reaches zero
        setIsTimerActive(false);
        setTimeLeft(0); // Set time left to 0
        window.location.reload(); // Reload the page when the timer ends
      } else {
        setTimeLeft(remainingTime);
      }
    }
  };


  // Decrypt data function
  const getData = (key: string, iv: string, data: string) => {
    console.log('payloadKeys-->', key, iv, data)
    const decipher = createDecipheriv('aes-256-cbc', key, iv);
    let result: any = decipher.update(data, 'hex', 'utf8');
    result += decipher.final('utf8');
    console.log('payloadKeys', iv, key, data, result)
    return result;
  }

  // Function to convert keys to desired wording
  const formatKey = (key: any) => {
    switch (key) {
      case 'first_name':
        return 'First Name';
      case 'dob':
        return 'DOB';
      default:
        return key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
    }
  };
  // Function to get comma-separated string of keys with false values
  const getFalseKeysString = (matches: any) => {
    return Object?.entries(matches)
      .filter(([key, value]) => value === false)
      .map(([key]) => formatKey(key))
      .join(', ');
  };




  //  Function for Updating the customer details in RACDB and DEA DB
  const updateCustomerDetailsFn = async (status?: string, governmentId?: string, governmentIdType?: string, intellicheckData?: any) => {
    setScreenLoader(true)
    const updateApprovalPayload = {
      decisionId: actualIdentityDetails?.decisionEngineId,
      isManageApproval: true,
      verifyCode: actualIdentityDetails?.verifyCode ? actualIdentityDetails?.verifyCode : '',
      verificationDocuments: [
        {
          verificationStatus: status == 'Passed' ? "VERIFIED" : 'PENDREW',
          verified: status == 'Passed' ? 'Y' : 'N',
          verifyType: "ID",
          clientId: "RACPad",
          modifiedBy: status == 'Passed' ? 'Intellicheck' : currentEmpId
        },
      ],
    }
    const updateCustomerPaylaod = {
      customerId: customerId,
      governmentIdType: governmentIdType ? governmentIdType : 'OTHER',
      governmentId: governmentId,
      governmentIdIssuedState: intellicheckData?.id_document?.province_code

    };
    let manageApprovalRes: any
    let updateCustomerRes: any
    if (status == 'Passed') {
      updateCustomerRes = await UpdateCustomerDetails(updateCustomerPaylaod)
      manageApprovalRes = await UpdateVerificationOptimization(updateApprovalPayload)
      const updatedDetails: any = {
        ...identityDetails,
        governmentIdType: governmentIdType ? governmentIdType : 'OTHER',
        governmentId: governmentId,
        issuedState: intellicheckData?.id_document?.province_code,
        verified: true,
        verifiedBy: currentEmpId,
        verifiedSource: 'Intellicheck',
        verifiedDate: moment().format('MM/DD/YYYY')
      };
      console.log("updatedDetailscheckk", updatedDetails)
      console.log("actualIdentityDetailscheck", actualIdentityDetails)
      setIdentityDetails((prev: any) => ({
        ...prev,
        ...updatedDetails
      }));
      setActualIdentityDetials((prev: any) => ({
        ...prev,
        ...updatedDetails
      }));
    }
    else {
      updateCustomerRes = await UpdateCustomerDetails(updateCustomerPaylaod)
      manageApprovalRes = await UpdateVerificationOptimization(updateApprovalPayload)
      const updatedDetails: any = {
        ...identityDetails,
        verified: false,
      };
      console.log("updatedDetailscheckk passed with flag", updatedDetails)
      console.log("actualIdentityDetailscheck passed with flag", actualIdentityDetails)
      setIdentityDetails((prev: any) => ({
        ...prev,
        ...updatedDetails
      })); setActualIdentityDetials((prev: any) => ({
        ...prev,
        ...updatedDetails
      }));
    }
    if (manageApprovalRes) {
      const getCustomerResponse = await GetCustomer(customerId || '', window.sessionStorage.getItem('storeNumber') || '');
      console.log('Inside the SetGetCustomerInfo', getCustomerResponse);
      setGetCustomerInfoResponse(getCustomerResponse?.data);
      setGetApprovalRes(getCustomerResponse?.data?.GetApproval?.value?.customerApprovalDetails);
    }
    const customerArray: any = window.sessionStorage.getItem('IntellicheckFlag')
    if (customerArray) {
      const currentSessionValue: any = JSON.parse(customerArray);
      const filterVlue: any = currentSessionValue ? currentSessionValue.filter((item: any) => item !== customerId) : null
      const stringified: any = filterVlue ? JSON.stringify(filterVlue) : null;
      filterVlue ? window.sessionStorage.setItem('IntellicheckFlag', stringified) : null
    }
    setScreenLoader(false)
  }
  //  Function for getting the intellicheck response
  const getIntellicheckStatusFn = async () => {

    setScreenLoader(true)
    const getIntellicheckStatusRes = await getIntellicheckStatus(actualIdentityDetails?.decisionEngineId, 'rac')
    console.log("getIntellicheckStatusResgetIntellicheckStatusRes", getIntellicheckStatusRes)
    if (getIntellicheckStatusRes?.status == 200) {
      if (getIntellicheckStatusRes?.data?.data?.status == 'incomplete') {
        setIntellicheckStatus('')
        setScreenLoader(false)

      }
      else {
        if (Number(getIntellicheckStatusRes?.data?.data?.id_score) >= Number(featureFlagDetails?.IntellicheckPassScoreLimit) && Object.values(getIntellicheckStatusRes?.data?.data?.matches).every(value => value === true)
        ) {
          uploadVerificationDocumentsFn(getIntellicheckStatusRes?.data?.data?.images?.front)
          const decrytptedValues: any = await decryptData({
            decryptData: {
              governmentId: getIntellicheckStatusRes?.data?.data?.id_document?.number
            },
            encryptKey,
            ivKey,
          });
          const governmentId = decrytptedValues?.data?.decryptData?.governmentId ? getData(encryptKey, ivKey, decrytptedValues?.data?.decryptData?.governmentId
          ) : actualIdentityDetails?.governmentId
          const governmentIdType = govtIDTypeOptions?.filter((obj: any) => obj?.value?.toLocaleLowerCase() == (getIntellicheckStatusRes?.data?.data?.id_document?.type == 'na_dl' ? 'dl' : getIntellicheckStatusRes?.data?.data?.id_document?.type == 'passport' ? 'upass' : 'other'))[0]?.value
          console.log('Goverenment Id Type Passed', governmentIdType);
          await updateCustomerDetailsFn('Passed', governmentId, governmentIdType, getIntellicheckStatusRes?.data?.data)
          setScreenLoader(false)
          setIntellicheckStatus('Passed')


          const updatedDetails: any = {
            ...identityDetails,
            governmentIdType: governmentIdType ? governmentIdType : 'OTHER',
            governmentId: governmentId,
            issuedState: getIntellicheckStatusRes?.data?.data?.id_document?.province_code,
            verified: true,
            verifiedSource: 'Intellicheck',
            verifiedBy: currentEmpId,

          };
          setIdentityDetails((prev: any) => ({
            ...prev,
            ...updatedDetails
          })); setActualIdentityDetials((prev: any) => ({
            ...prev,
            ...updatedDetails
          }));
          setGovIdClicked(true)


        }
        else if (Number(getIntellicheckStatusRes?.data?.data?.id_score) >= Number(featureFlagDetails?.IntellicheckPassScoreLimit) && Object.values(getIntellicheckStatusRes?.data?.data?.matches).some(value => value === false)) {
          setScreenLoader(true)


          const decrytptedValues: any = await decryptData({
            decryptData: {
              governmentId: getIntellicheckStatusRes?.data?.data?.id_document?.number
            },
            encryptKey,
            ivKey,
          });

          const governmentId = decrytptedValues?.data?.decryptData?.governmentId ? getData(encryptKey, ivKey, decrytptedValues?.data?.decryptData?.governmentId
          ) : actualIdentityDetails?.governmentId
          const governmentIdType = govtIDTypeOptions?.filter((obj: any) => obj?.value?.toLocaleLowerCase() == (getIntellicheckStatusRes?.data?.data?.id_document?.type == 'na_dl' ? 'dl' : getIntellicheckStatusRes?.data?.data?.id_document?.type == 'passport' ? 'upass' : 'other'))[0]?.value
          console.log('Goverenment Id Type Passed With Flag', governmentIdType);

          await updateCustomerDetailsFn('PassedWithFlag', governmentId, governmentIdType, getIntellicheckStatusRes?.data?.data)
          setScreenLoader(false)

          setIntellicheckStatus('PassedWithFlag')

          const updatedDetails: any = {
            ...identityDetails,
            governmentIdType: governmentIdType ? governmentIdType : 'OTHER',
            governmentId: governmentId,
            issuedState: getIntellicheckStatusRes?.data?.data?.id_document?.province_code,
            verified: false,
            verifiedSource: 'RACPad',
            verifiedBy: currentEmpId,
          };
          setIdentityDetails((prev: any) => ({
            ...prev,
            ...updatedDetails
          }));
          setActualIdentityDetials((prev: any) => ({
            ...prev,
            ...updatedDetails
          }));
          setGovIdClicked(true)

          uploadVerificationDocumentsFn(getIntellicheckStatusRes?.data?.data?.images?.front)
          setIntellicheckmismatchedValues(getFalseKeysString(getIntellicheckStatusRes?.data?.data?.matches))
        }
        else {
          setScreenLoader(true)

          const updateApprovalPayload = {
            decisionId: actualIdentityDetails?.decisionEngineId,
            isManageApproval: true,
            verifyCode: actualIdentityDetails?.verifyCode ? actualIdentityDetails?.verifyCode : '',
            verificationDocuments: [
              {
                verificationStatus: 'PENDREW',
                verified: 'N',
                verifyType: "ID",
                clientId: "RACPad",
                modifiedBy: currentEmpId
              },
            ],
          }
          const decrytptedValues: any = await decryptData({
            decryptData: {
              governmentId: getIntellicheckStatusRes?.data?.data?.id_document?.number
            },
            encryptKey,
            ivKey,
          });
          const governmentId = decrytptedValues?.data?.decryptData?.governmentId ? getData(encryptKey, ivKey, decrytptedValues?.data?.decryptData?.governmentId
          ) : actualIdentityDetails?.governmentId
          const governmentIdType = govtIDTypeOptions?.filter((obj: any) => obj?.value?.toLocaleLowerCase() == (getIntellicheckStatusRes?.data?.data?.id_document?.type == 'na_dl' ? 'dl' : getIntellicheckStatusRes?.data?.data?.id_document?.type == 'passport' ? 'upass' : 'other'))[0]?.value
          console.log('Goverenment Id Type Failed', governmentIdType);
          const updateCustomerPaylaod = {
            customerId: customerId,
            governmentIdType: governmentIdType ? governmentIdType : 'OTHER',
            governmentId: governmentId,
            governmentIdIssuedState: getIntellicheckStatusRes?.data?.data?.id_document?.province_code
          };
          const manageApprovalRes: any = await UpdateVerificationOptimization(updateApprovalPayload)
          const updateCustomerRes = await UpdateCustomerDetails(updateCustomerPaylaod)
          if (manageApprovalRes) {
            const getCustomerResponse = await GetCustomer(customerId || '', window.sessionStorage.getItem('storeNumber') || '');
            console.log('Inside the SetGetCustomerInfo1', getCustomerResponse);
            setGetCustomerInfoResponse(getCustomerResponse?.data);
            setGetApprovalRes(getCustomerResponse?.data?.GetApproval?.value?.customerApprovalDetails);
          }
          const updatedDetails: any = {
            ...identityDetails,
            verified: false,
            governmentIdType: governmentIdType ? governmentIdType : 'OTHER',
            governmentId: governmentId,
            issuedState: getIntellicheckStatusRes?.data?.data?.id_document?.province_code
          };
          console.log("updatedDetailscheckk failed", updatedDetails)
          console.log("actualIdentityDetailscheck failed", actualIdentityDetails)
          setIdentityDetails((prev: any) => ({
            ...prev,
            ...updatedDetails
          }));
          setActualIdentityDetials((prev: any) => ({
            ...prev,
            ...updatedDetails
          }));
          await uploadVerificationDocumentsFn(getIntellicheckStatusRes?.data?.data?.images?.front)
          const customerArray: any = window.sessionStorage.getItem('IntellicheckFlag')
          if (customerArray) {
            const currentSessionValue: any = JSON.parse(customerArray);
            const filterVlue: any = currentSessionValue ? currentSessionValue.filter((item: any) => item !== customerId) : null
            const stringified: any = filterVlue ? JSON.stringify(filterVlue) : null;
            filterVlue ? window.sessionStorage.setItem('IntellicheckFlag', stringified) : null
          }
          setGovIdClicked(true)
          setScreenLoader(false)
          setIntellicheckStatus('Failed')
          setIntellicheckmismatchedValues(getFalseKeysString(getIntellicheckStatusRes?.data?.data?.matches))
        }
      }
    }
    else {
      setScreenLoader(false)
      // seterrorMessageText('Verification failed due to a technical issue; please resend the verification link')
      // setSomethingWentWrongOpen(true)
      setIntellicheckStatus('')
      setIntellicheckmismatchedValues('')

    }
  }
  // Function for retrieving the verification Documsnts
  const getVerificationDocumentsFn = async () => {
    setScreenLoader(true)
    const payLoad: any = {
      identifier: actualIdentityDetails.decisionEngineId,
      documentStatus: 'signed',
      actionType: 'print',
      identifierType: 'CUST',
      customerVerificationDocument: 'true'
    };
    const getVerificationDocumentsRes = await getVerificationDocuments(payLoad)
    console.log("getVerificationDocumentsRes", getVerificationDocumentsRes)

    if (getVerificationDocumentsRes?.status == 200 && getVerificationDocumentsRes?.data?.documents && getVerificationDocumentsRes?.data?.documents.length > 0) {
      // Filter for IdentityDocuments
      const identityDocuments: any = getVerificationDocumentsRes?.data?.documents.filter((doc: { documentName: any; }) => doc.documentName === "IdentityDocument");
      // Find the most recent one
      const recentIdentityDocument: any = identityDocuments.reduce((latest: any, current: any) => {
        return new Date(latest.createdDate) > new Date(current.createdDate) ? latest : current;
      });
      console.log("recentIdentityDocument0", [recentIdentityDocument])
      setCustomerIdentityDocuments([recentIdentityDocument])
    }
    setScreenLoader(false)


  }

  // Function for Uploading the customer's verification Documents
  const uploadVerificationDocumentsFn = async (base64?: any) => {
    setScreenLoader(true)

    if (base64) {
      const payLoad: any = {
        "isVerificationDocument": true,
        "customerApprovalId": actualIdentityDetails?.decisionEngineId,
        "documents": [{
          "documentType": "IDENTTYDOC",
          "file": base64
        }]
      }
      const uploadVerificationDocumentRes = await uploadVerificationDocuments(payLoad)
      if (uploadVerificationDocumentRes?.status == 200) {
        await getVerificationDocumentsFn()
        setScreenLoader(false)
      }
    }

    setScreenLoader(false)
  }


  const handleEditButton = async (isAuthenticated: boolean) => {
    // eslint-disable-next-line no-console

    if (isAuthenticated || !identityDetails.verified) {
      setScreenLoader(true);
      setSecondLevelAuth(false);
      let updatePayload: any = identityDetails;
      updatePayload.modifiedBy = currentEmpId;
      const updateDetailsResponse: boolean =
        await updateCustomerAndVerification(
          updatePayload,
          "ID",
          customerId ? customerId : "",
          setGetCustomerInfoResponse,
          setGetApprovalRes,
          actualIdentityDetails,
          setIntellicheckStatus

        );
      if (updateDetailsResponse) {
        setEditEnabled(false);
        const updatedDetails: IdentityDetailsInfo = {
          ...identityDetails,
          verified: identityDetails.verified,
          verifiedBy: currentEmpId,
          verifiedSource: 'RACPad',
          verifiedDate: moment().format('MM/DD/YYYY'),
          governmentId: identityDetails?.governmentId,
          governmentIdType: identityDetails?.governmentIdType,
          issuedState: identityDetails?.issuedState
        };

        setActualIdentityDetials((prev: any) => ({
          ...prev,
          ...updatedDetails
        }));
      } else {
        setEditEnabled(false);
        setIdentityDetails(identityDetails);
      }
      setScreenLoader(false);
    } else {
      setSecondLevelAuth(true);
    }
  };

  const validateFields = () => {
    const errorMessage: IdentityDetailsInfo = {
      governmentId: "",
      governmentIdType: "",
      issuedState: "",
      verified: false,
      verifiedDate: "",
      overrideEnabled: false,
      decisionEngineId: "",
      verifiedSource: "",
      govtIdDecrpted: "",
      verifyCode: "",
      verifiedBy: "",
    };

    if (!isNotNullVariable(identityDetails.governmentIdType)) errorMessage.governmentIdType = 'Required'
    else errorMessage.governmentIdType = '';
    if (!isNotNullVariable(identityDetails.governmentId)) errorMessage.governmentId = 'Required'
    else errorMessage.governmentId = '';

    setIdentityErrorMessage(errorMessage);
    return (!errorMessage.governmentId && !errorMessage.governmentIdType)
  }


  // const updatedGovtIdTypes = govtIDTypeOptions?.filter((element: DropdownOptions) => GOVT_ID_TYPE_CVO.includes(element.value));
  const handleBlur = () => {
    setGovIdClicked(true);
  };

  const handleFocus = () => {
    setGovIdClicked(false);
  };
  const removeErrorMessages = () => {
    const errorMessage: any = {
      governmentId: '',
      governmentIdType: '',
      issuedState: '',
      verified: false,
      verifiedDate: '',
      overrideEnabled: false,
      decisionEngineId: '',
      verifiedSource: ''
    };
    setIdentityErrorMessage(errorMessage);
  }




  const sendIntellicheckLink = async () => {
    setScreenLoader(true)

    console.log("check IntellicheckDocumentSides ", featureFlagDetails?.IntellicheckDocumentSides)

    const documentSides = JSON.parse(featureFlagDetails?.IntellicheckDocumentSides.slice(1, -1)).values;
    console.log('documentSides', documentSides)
    const payload: any = {
      "requestedDocumentSides": documentSides,
      "globalCustomerId": `cust-${actualIdentityDetails?.decisionEngineId}`,
      "businessUnit": "rac",
      "firstName": customerBasicInformation?.firstName ? customerBasicInformation?.firstName : '',
      "lastName": customerBasicInformation?.lastName ? customerBasicInformation?.lastName : '',
      "dateOfBirth": customerBasicInformation?.dateOfBirthDec ? customerBasicInformation?.dateOfBirthDec : '',
      "addressLine1": customerBasicInformation?.addressLine1 ? customerBasicInformation?.addressLine1 : '',
      "city": customerBasicInformation?.city ? customerBasicInformation?.city : '',
      "provinceCode": customerBasicInformation?.state ? customerBasicInformation?.state : '',
      "postalCode": customerBasicInformation?.zip ? customerBasicInformation?.zip : '',
      "countryCode": "US",
      "phoneNumber": `+1${intellicheckPhoneNumber.replace(/[\s()\-]/g, '')}`
    }

    const SendIntellicheckRes: any = await SendIntellicheck(payload)
    console.log("SendIntellicheckRes", SendIntellicheckRes)
    setScreenLoader(false)
    if (SendIntellicheckRes?.status == 200 && SendIntellicheckRes?.data?.value?.status == 'ok') {
      const customerArray: any = window.sessionStorage.getItem('IntellicheckFlag')
      if (customerArray) {
        const currentSessionValue: any = JSON.parse(customerArray);
        currentSessionValue.includes(customerId) ? currentSessionValue : currentSessionValue.push(customerId)
        const stringified = JSON.stringify(currentSessionValue);
        window.sessionStorage.setItem('IntellicheckFlag', stringified);
      }
      else {
        const currentSessionValue: any = [];
        currentSessionValue.push(customerId);
        const stringified = JSON.stringify(currentSessionValue);
        window.sessionStorage.setItem('IntellicheckFlag', stringified);
      }
      const initialTime = Number(featureFlagDetails?.IntellicheckTimer) * 60 - 1;
      timeLeft = initialTime
      setTimeLeft(timeLeft);
      timeRemainingRef.current = initialTime;
      isTimerActive = false
      setIsTimerActive(isTimerActive)
      handleStartTimer()
      setSuccessfullPopup(true)
    }
    else {
      seterrorMessageText('Intellicheck service is temporarily down. Please retry shortly.')
      setSomethingWentWrongOpen(true)
    }
  }


  const SendIdentityVerificationTextFn = () => {
    return (
      <>
        <RACTextbox
          type='text'
          inputlabel="Phone *"
          value={intellicheckPhoneNumber ? intellicheckPhoneNumber : ''}
          className={classes.inputTextBoxClass}
          OnChange={(e: any) => { setIntellicheckPhoneNumber(formatPhone(e.target.value)) }}
        />

        <div style={{ display: 'flex', float: 'right' }}>
          <div style={{ marginRight: '15px' }}>
            <RACButton
              style={{
                marginTop: '35px',

              }}
              variant="outlined"
              color="secondary"
              onClick={() => {
                setSendIdentityVerificationTextOpen(false);
              }}
            >
              Cancel
            </RACButton></div>

          <div>
            <RACButton
              variant="contained"
              color="primary"
              style={{
                marginTop: '35px',
                marginRight: '40px',
                padding: '12px 31px'
              }}
              disabled={intellicheckPhoneNumber?.length > 9 ? false : true}
              onClick={() => {
                setSendIdentityVerificationTextOpen(false);
                sendIntellicheckLink()

              }}
            >
              Send
            </RACButton></div>


        </div >

      </>
    );
  }

  const successfullPopupFn = () => {
    return (
      <div>

        <Grid item md={12} className={classes.textCenter}>
          <SuccessIcon></SuccessIcon>
          <Typography style={{ marginTop: '13px', fontFamily: 'OpenSans-bold', fontSize: '17px' }}>
            Message Sent Successfully
          </Typography>
        </Grid>
        <Grid style={{ textAlign: 'center', marginTop: '23px' }}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setSuccessfullPopup(false)}
          >
            OK
          </RACButton>
        </Grid>
      </div >
    );
  };
  const somethingWentWrongPopup = () => {
    return (
      <Grid container>
        <Grid item md={12} style={{ textAlign: "center" }}>
          <WarningIcon></WarningIcon>
          <Typography
            variant={"body2"}
            style={{
              fontFamily: "OpenSans-bold",
              fontSize: "16px",
              letterSpacing: 0,
              marginBottom: "25px",
            }}
          >
            {errorMessageText ? errorMessageText : 'Something went wrong'}
          </Typography>

          <RACButton
            variant="contained"
            color="primary"
            id="assignAlertBtn"
            onClick={() => {
              setSomethingWentWrongOpen(false)
              seterrorMessageText('')
            }}
          >
            ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };


  //  Function fro viewing the pdf


  const viewPDFclick = (idDocBse64?: any, idDocUrl?: any) => {
    const doc = new jsPDF();

    const base64: any = idDocBse64

    // Add the image to the PDF (x, y, width, height)
    doc.addImage(base64, 'PNG', 10, 10, 180, 160);

    // Create PDF as a Blob
    const pdfBlob = doc.output('blob');

    // Open the Blob in a new tab
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, '_blank');
  };
  // Dynamically Bindign the document
  const DocumentDynamicBinding = () => {
    return customerIdentityDocuments?.map((obj: any, index: any) => {
      return (
        <Grid item md={12} className={classes.mb3} key={index}>
          <PDFImage style={{ float: "left" }}></PDFImage>
          <Typography
            variant="caption"
            className={`${classes.formLabel} ${classes.ms1}`}
          >
            {obj.documentName}.pdf
          </Typography>



          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {

              setidentityDocsecondLevelAuth(true)
              setidDocUrl(obj.documentUrl)
              setIdDocBse64(obj.file)
            }}
          >
            <ViewEyeIcon style={{ width: '58px', height: '28px', marginLeft: '4px' }} />
          </span>
        </Grid>
      );
    });
  };

  return (

    <>


      <Grid container className={`${classes.mt10px}`}>

        <Grid container md={12} className={`${classes.mt10px}`}>
          <Grid item md={2}>
            <Typography
              className={`${classes.fontSize16px} ${classes.fontOpensansBold}`}
            >
              Identity Document
            </Typography>
          </Grid>
        </Grid>
        <Grid container md={12} spacing={2} className={`${classes.mt10px}`}>
          <Grid item md={3} lg={3} xs={4}>

            {featureFlagDetails?.IdentityIntellicheckVerification == '1' ? <span style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <span style={{ marginRight: '8px', fontFamily: 'OpenSans-semibold' }}>Gov ID Type</span><span style={{ color: 'red', padding: '0px 8px 0px 0px', fontFamily: 'OpenSans-bold' }}>*</span>
              <ContactIcon />
            </span> : null}

            <RACSelect
              required={featureFlagDetails?.IdentityIntellicheckVerification == '1' ? false : true}
              inputLabel={featureFlagDetails?.IdentityIntellicheckVerification == '1' ? '' : 'Gov ID Type'}
              options={govtIDTypeOptions}
              loading={screenLoader}
              onChange={(e: any) =>
                updateObjectWithMultipleKeys(setIdentityDetails, {
                  governmentIdType: e.target.value,
                  ...(e.target.value == "NONE"
                    ? {
                      governmentId: "",
                      govtIdDecrpted: "",
                      issuedState: "",
                    }
                    : null),
                })
              }
              defaultValue={
                !editEnabled
                  ? actualIdentityDetails.governmentIdType
                  : identityDetails.governmentIdType
              }
              errorMessage={errorMessage.governmentIdType}
              isDisabled={!editEnabled}
            />
          </Grid>
          <Grid item md={3} lg={3} xs={4}>

            {featureFlagDetails?.IdentityIntellicheckVerification == '1' ? <span style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <span style={{ marginRight: '8px', fontFamily: 'OpenSans-semibold' }}>Gov ID</span><span style={{ color: 'red', padding: '0px 8px 0px 0px', fontFamily: 'OpenSans-bold' }}>*</span>
              <ContactIcon />
            </span> : null}

            <RACTextbox
              type="text"
              required={featureFlagDetails?.IdentityIntellicheckVerification == '1' ? false : true}
              inputlabel={featureFlagDetails?.IdentityIntellicheckVerification == '1' ? '' : 'Gov ID'}
              maxlength={30}
              // onFocus={handleFocus}
              Onblur={handleBlur}
              OnChange={(e: any) => {
                setGovIdClicked(false);
                updateObjectStateVariable(
                  setIdentityDetails,
                  "governmentId",
                  e.target.value.replace(/[^a-zA-Z0-9*]/g, '')
                );
              }}
              value={
                !editEnabled
                  ? showDecryptedValues ? actualIdentityDetails.govtIdDecrpted : isGovtIdClicked ? getMaskedValue(actualIdentityDetails.governmentId) : actualIdentityDetails.governmentId
                  : showDecryptedValues ? identityDetails.govtIdDecrpted : isGovtIdClicked ? getMaskedValue(identityDetails.governmentId) : (!isGovtIdClicked && !identityDetails.governmentId?.includes('*')) ? identityDetails.governmentId : !actualIdentityDetails?.governmentId ? identityDetails.governmentId : getMaskedValue(identityDetails.governmentId)
              }
              errorMessage={errorMessage.governmentId}
              disabled={
                !editEnabled || identityDetails.governmentIdType == "NONE" || showDecryptedValues
              }
            />
          </Grid>
          <Grid item md={3} lg={3} xs={4}>

            {featureFlagDetails?.IdentityIntellicheckVerification == '1' ? <span style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <span style={{ marginRight: '8px', fontFamily: 'OpenSans-semibold' }}>State</span><span style={{ color: 'red', padding: '0px 8px 0px 0px', fontFamily: 'OpenSans-bold' }}>*</span>
              <ContactIcon />
            </span> : null}

            <RACSelect
              required={featureFlagDetails?.IdentityIntellicheckVerification == '1' ? false : true}
              inputLabel={featureFlagDetails?.IdentityIntellicheckVerification == '1' ? '' : 'State'}

              options={stateOptions}
              loading={screenLoader}
              onChange={(e: any) =>
                updateObjectWithMultipleKeys(setIdentityDetails, {
                  issuedState: e.target.value,
                })
              }
              defaultValue={
                !editEnabled
                  ? actualIdentityDetails.issuedState
                  : identityDetails.issuedState
              }
              errorMessage={errorMessage.issuedState}
              isDisabled={
                !editEnabled || identityDetails.governmentIdType == "NONE"
              }
            />
          </Grid>
          {featureFlagDetails?.IdentityIntellicheckVerification == '1' ?


            <Grid item md={3} lg={3} xs={4}>
              <span style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <span style={{ marginRight: '8px', fontFamily: 'OpenSans-semibold' }}>Identity Document</span><span style={{ color: 'red', padding: '0px 8px 0px 0px', fontFamily: 'OpenSans-bold' }}>*</span>
                <ContactIcon />
              </span>
              {customerIdentityDocuments?.length > 0 ? DocumentDynamicBinding() : <span style={{ position: 'absolute', marginTop: '6px' }}>No Documents Found</span>}

            </Grid> : null}
        </Grid>
        {((!actualIdentityDetails.verified || editEnabled) && actualIdentityDetails.decisionEngineId) || intellicheckStatus ? <VerificationComponent value={verificationTabPayload} /> : null}
        {/* <OverrideComponent value={verificationTabPayload}/> */}
        <Grid
          container
          className={`${classes.mt10px} ${classes.displayFlex} ${classes.flexEnd} ${classes.pr2p}`}
          md={12}
        >
          <Grid item md={10}>
            {actualIdentityDetails.verified ? (
              <DynamicVerifiedStatus
                value={{
                  verifiedBy: actualIdentityDetails.verifiedBy,
                  verifiedSource: actualIdentityDetails.verifiedSource,
                  verifiedOn: actualIdentityDetails.verifiedDate,
                }}
              />
            ) : null}
          </Grid>
          <Grid item md={2} className={`${classes.alignRight}`}>
            {editEnabled ? (
              <RACButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  setEditEnabled(false);
                  setIdentityDetails(actualIdentityDetails);
                  removeErrorMessages();
                }}
              >
                Cancel
              </RACButton>
            ) : null}
            <RACButton
              variant="contained"
              color="primary"
              className={`${classes.ml10px} ${classes.alignRight}`}
              // disabled={editEnabled ? !mandatoryFieldsCheck : false}
              onClick={async () => {
                editEnabled ? validateFields() ? handleEditButton(false) : null : (setEditEnabled(true), updateObjectStateVariable(setIdentityDetails,
                  "verified",
                  false));
              }}
            >
              {`${editEnabled ? "Save" : "Edit"}`}
            </RACButton>
          </Grid>
        </Grid>
        {secondLevelAuth ? (
          <SecondFactor
            setTwoFactorCancelClick={() => {
              setSecondLevelAuth(false);
              setEditEnabled(false);
              setIdentityDetails(actualIdentityDetails);
            }}
            setTwoFactorCompleted={(e: any) => handleEditButton(e)}
            moduleName={MODULE_NAME.IDENTITY_VERIFICATION}
            currentRole={currentRole}
          />
        ) : null}


        {identityDocsecondLevelAuth ? (
          <SecondFactor
            setTwoFactorCancelClick={() => {
              setidentityDocsecondLevelAuth(false);
            }}
            setTwoFactorCompleted={(e: any) => {
              setidentityDocsecondLevelAuth(false),
                viewPDFclick(idDocBse64, idDocUrl)
            }}
            moduleName={MODULE_NAME.IDENTITY_DOCUMENT}
            currentRole={currentRole}
          />
        ) : null}

        <RACModalCard
          isOpen={sendIdentityVerificationTextOpen}
          closeIcon={true}
          title="Send Identity Verification Text"
          maxWidth="xs"
          borderRadius={modalCardRadius}
          onClose={() => setSendIdentityVerificationTextOpen(false)}
        >
          {SendIdentityVerificationTextFn()}
        </RACModalCard>

        <RACModalCard
          isOpen={successfullPopup}
          closeIcon={true}
          maxWidth="xs"
          borderRadius={modalCardRadius}
          onClose={() => setSuccessfullPopup(false)}
        >
          {successfullPopupFn()}
        </RACModalCard>
        <RACModalCard
          TitleClassName={classes.TitleClassName}
          isOpen={somethingWentWrongOpen}
          closeIcon={false}
          maxWidth="xs"
          borderRadius={modalCardRadius}
        >
          {somethingWentWrongPopup()}
        </RACModalCard>

      </Grid>
    </>
  );
}
