/* eslint-disable */
import React, { ChangeEvent, Fragment, useContext, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  RACButton,
  RACCOLOR,
  RACSelect,
  RACTextbox,
  RACToggle,
  Typography,
} from "@rentacenter/racstrap";

import {
  API_ERROR_MESSAGE,
  EMPTY_STRING,
  GOVT_ID_REGEX,
  INVALID_EMAIL_FORMAT,
  MAIL_REGEX,
  MULTIPLE_SPACE_REMOVAL_REGEX,
  NAME_REPLACEABLE_REGEX,
  ONLY_ALPHABETS_REGEX,
  REQUIRED_ERROR,
  STRING_NO,
  STRING_YES,
} from "../../constants/constants";

import {
  capitalizeFirstLetters,
  valueExistsInDropdown,
} from "../../utils/utils";

import {
  DropdownOptions,
  PathParams,
  UpdateCustomerPayload,
} from "../../interface/globalInterface";
import { CustomerBasicInformation } from "../../interface/updateCustomerInterface";

import { updateCustomerStyles } from "../../jsStyles/updateCustomerStyles";

import { DynamicAlertPopup } from "./DynamicAlertPopup";
import { SSNField } from "./SSNField";
import { MaskableDatePicker } from "./MaskableDatePicker";

import { linkCustomerInfoInWeb } from "../../apiHandler/updateCustomerApiHandler";
import SecondFactor from "../../../racstrapComponent/manageCustomer/secondAuthen";
import { useParams } from "react-router-dom";
import { formBasicInfoPayload, isNotNullVariable, updateObjectStateVariable } from "./Utils";
import { UpdateCustomerDetails } from "../../../api/user";
import { UpdateCustomerContext } from "../../context/UpdateCustomerContext";

interface BasicInformationProps {
  data: BasicInformationDataProps;
  function: BasicInformationFunctionProps;
}

interface BasicInformationDataProps {
  dropdownLoader: boolean;
  showDecryptedValues: boolean;
  customerId: string;
  dropdownsData: {
    govtIDType: DropdownOptions[];
    state: DropdownOptions[];
    salutation: DropdownOptions[];
    suffix: DropdownOptions[];
  };
  agreementTransferInit: boolean;
  requiredFieldExists: boolean;
  basicInformation: CustomerBasicInformation;
  showCustomerInfo: boolean;
  updatedBasicInfo: CustomerBasicInformation;
  isMaskedDataHasChanged: any;
  govIdFocused: boolean;
  legalHoldScreen: boolean;
  coworkerRole: string;
}

interface BasicInformationFunctionProps {
  setBasicInformation: React.Dispatch<
    React.SetStateAction<CustomerBasicInformation>
  >;
  setScreenLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCustomerInfo: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDecryptedValues: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdatedBasicInfo: React.Dispatch<
    React.SetStateAction<CustomerBasicInformation>
  >;
  setIsCustomerInfoUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setisMaskedDataHasChanged: any;
  setGovIdFocused: React.Dispatch<React.SetStateAction<boolean>>;
}

export function BasicInformation(props: BasicInformationProps): JSX.Element {
  const classes = updateCustomerStyles();
  // const [popupMessage, setPopupMessage] = useState<string>(EMPTY_STRING);
  // const [showPopup, setShowPopup] = useState<boolean>(false);
  // const [popupType, setPopupType] = useState<string>(EMPTY_STRING);
  const [showSecondLevlAuth, setShowSecondLevelAuth] = useState<boolean>(false);
  const [moduleName, setModuleName] = useState<string>(EMPTY_STRING);

  const [mailErrorMessage, setMailErrorMessage] =
    useState<string>(EMPTY_STRING);

  const {
    basicInformation,
    dropdownLoader,
    dropdownsData,
    agreementTransferInit,
    showDecryptedValues,
    requiredFieldExists,
    showCustomerInfo,
    updatedBasicInfo,
    govIdFocused,
    legalHoldScreen
  } = props.data;
  const { customerId } = useParams<PathParams>();

  const {
    setBasicInformation,
    setScreenLoader,
    setShowCustomerInfo,
    setShowDecryptedValues,
    setUpdatedBasicInfo,
    setIsCustomerInfoUpdate,
    setisMaskedDataHasChanged,
    setGovIdFocused,
  } = props.function;

  const {
    configurations,
    showPopup,
    setShowPopup,
    popupType,
    setPopupType,
    popupMessage,
    setPopupMessage,
    currentRole,
    getCustomerInfoResponse,
    setGetCustomerInfoResponse
  } = useContext(UpdateCustomerContext);
  const isNotCustVerfOpt: boolean =
    !configurations.customerVerificationOptimization;
  const saveButtonDisable: boolean =
    basicInformation.firstName != EMPTY_STRING &&
    basicInformation.lastName != EMPTY_STRING &&
    basicInformation.dateOfBirth != EMPTY_STRING;

  const [errorMessage, setErrorMessage] = useState<any>({
    firstName: '',
    lastName: '',
    ssn: '',
    dateOfBirth: ''
  })

  const validateFields = () => {
    const errorMessage = {
      firstName: '',
      lastName: '',
      ssn: '',
      dateOfBirth: ''
    }
    if (!isNotNullVariable(updatedBasicInfo.firstName)) errorMessage.firstName = 'Required'
    else errorMessage.firstName = '';
    if (!isNotNullVariable(updatedBasicInfo.lastName)) errorMessage.lastName = 'Required'
    else errorMessage.lastName = '';
    if (!isNotNullVariable(updatedBasicInfo.dateOfBirth)) errorMessage.dateOfBirth = 'Required'
    else errorMessage.dateOfBirth = '';
    if (isNotNullVariable(updatedBasicInfo.ssn) && updatedBasicInfo?.ssn?.length < 9) errorMessage.ssn = 'Please Enter Valid SSN Number'
    else errorMessage.dateOfBirth = '';

    setErrorMessage(errorMessage);
    return !errorMessage.firstName && !errorMessage.lastName && !errorMessage.ssn && !errorMessage.dateOfBirth
  }

  /*** Handle On Change Functions ***/
  const saveOnChangeValue = (field: string, value: string): void => {
    try {
      if (basicInformation.isEditEnabled) {
        setBasicInformation({ ...basicInformation, [field]: value });
      } else {
        setUpdatedBasicInfo({ ...updatedBasicInfo, [field]: value });
      }
    } catch (error: any) {
      console.log("Error in saveOnChangeValue: ", error?.message);
    }
  };

  const handleCustomerName = (field: string, value: string): void => {
    try {
      setIsCustomerInfoUpdate(true);
      if (value === " ") {
        value = EMPTY_STRING;
      } else {
        value = value.replace(NAME_REPLACEABLE_REGEX, EMPTY_STRING);
        value = value.replace(MULTIPLE_SPACE_REMOVAL_REGEX, " ");
        value = capitalizeFirstLetters(value);
      }
      if (basicInformation.isEditEnabled) {
        setBasicInformation({
          ...basicInformation,
          [field]: value,
        });
      } else {
        setUpdatedBasicInfo({
          ...updatedBasicInfo,
          [field]: value,
        });
      }
    } catch (error: any) {
      console.log("Error in handleCustomerName: ", error?.message);
    }
  };

  const handleGovtIdType = (value: string): void => {
    try {
      setIsCustomerInfoUpdate(true);
      const basicInfo: CustomerBasicInformation = { ...basicInformation };

      if (value === "PASS") {
        basicInfo.governmentIdIssuedState = EMPTY_STRING;
      } else if (value === EMPTY_STRING || value === "NONE") {
        basicInfo.governmentId = EMPTY_STRING;
        basicInfo.governmentIdIssuedState = EMPTY_STRING;
      }
      basicInfo.governmentIdType = value;
      setBasicInformation(basicInfo);
    } catch (error: any) {
      console.log("Error in handleGovtIdType: ", error?.message);
    }
  };

  const handleGovtId = (value: string): void => {
    try {
      setIsCustomerInfoUpdate(true);
      value = value.replace(GOVT_ID_REGEX, EMPTY_STRING);
      setBasicInformation({ ...basicInformation, governmentId: value });
    } catch (error: any) {
      console.log("Error in handleGovtId: ", error?.message);
    }
  };

  const handleStateRequired = (selectedIdType: string) => {
    try {
      if (
        !selectedIdType ||
        selectedIdType === "NONE" ||
        selectedIdType === "PASS"
      ) {
        return true;
      }
      return false;
    } catch (error: any) {
      console.log("Error in handleStateRequired", error?.message);
      return false;
    }
  };

  const handleMiddleName = (value: string): void => {
    try {
      setIsCustomerInfoUpdate(true);
      if (basicInformation.isEditEnabled) {
        setBasicInformation({
          ...basicInformation,
          middleInitial: value.replace(ONLY_ALPHABETS_REGEX, EMPTY_STRING),
        });
      } else {
        setUpdatedBasicInfo({
          ...updatedBasicInfo,
          middleInitial: value.replace(ONLY_ALPHABETS_REGEX, EMPTY_STRING),
        });
      }
    } catch (error: any) {
      console.log("Error in handleMiddleName: ", error?.message);
    }
  };

  const handleRACCoworker = (): void => {
    try {
      setIsCustomerInfoUpdate(true);
      setisMaskedDataHasChanged((prevState: any) => ({
        ...prevState,
        dob: true,
      }));
      setModuleName("RAC Coworker");
      setShowSecondLevelAuth(true);
    } catch (error: any) {
      console.log("Error in handleRACCoworker: ", error?.message);
    }
  };

  const handleShowPersonalInfo = () => {
    try {
      if (showDecryptedValues) {
        setShowCustomerInfo(false);
        setShowDecryptedValues(false);
      } else {
        setShowSecondLevelAuth(true);
        setModuleName("ViewPIIData");
      }
    } catch (error: any) {
      console.log("Error in handleShowPersonalInfo: ", error?.message);
    }
  };

  const validateEmail = (): void => {
    try {
      const emailInput = basicInformation.emailAddress;
      if (emailInput.trim() === EMPTY_STRING) {
        setMailErrorMessage(REQUIRED_ERROR);
      } else if (!MAIL_REGEX.test(emailInput)) {
        setMailErrorMessage(INVALID_EMAIL_FORMAT);
      } else {
        setMailErrorMessage(EMPTY_STRING);
      }
    } catch (error: any) {
      console.log("Error in validateEmail: ", error?.message);
    }
  };

  const getGovIdTypeErrorMessage = (): string => {
    try {
      if (!dropdownLoader && dropdownsData.govtIDType.length == 1) {
        return API_ERROR_MESSAGE;
      }
      return EMPTY_STRING;
    } catch (error: any) {
      console.log("Error in disableGovtIdNumber: ", error?.message);
      return EMPTY_STRING;
    }
  };

  const disableGovtIdNumber = (): boolean => {
    try {
      if (
        agreementTransferInit ||
        showDecryptedValues ||
        basicInformation.governmentIdType ||
        basicInformation.governmentIdType === "NONE"
      ) {
        return true;
      }
      return false;
    } catch (error: any) {
      console.log("Error in disableGovtIdNumber: ", error?.message);
      return false;
    }
  };

  const getGovIdNumberValue = (): string => {
    try {
      let govIdNumber: string = EMPTY_STRING;
      if (
        showDecryptedValues ||
        (!govIdFocused && basicInformation.governmentId.length > 4)
      ) {
        govIdNumber = basicInformation.govtIdDecr;
      } else {
        govIdNumber =
          basicInformation.governmentId
            .substring(0, basicInformation.governmentId.length - 4)
            .replace(/[A-Za-z0-9.,-]/g, "*") +
          basicInformation.governmentId.substring(
            basicInformation.governmentId.length - 4
          );
      }
      return govIdNumber;
    } catch (error: any) {
      console.log("Error in getGovIdNumberValue: ", error?.message);
      return EMPTY_STRING;
    }
  };

  const handleStateDisable = (): boolean => {
    try {
      if (
        agreementTransferInit ||
        basicInformation.governmentIdType === EMPTY_STRING ||
        basicInformation.governmentIdType === "NONE" ||
        basicInformation.governmentIdType === "PASS"
      ) {
        return true;
      }
      return false;
    } catch (error: any) {
      console.log("Error in handleStateDisable: ", error?.message);
      return false;
    }
  };

  const handleStateErrorMessage = (): string => {
    try {
      let errorMessage: string = EMPTY_STRING;
      if (
        requiredFieldExists &&
        (!basicInformation.governmentIdType ||
          basicInformation.governmentIdType === "NONE" ||
          basicInformation.governmentIdType === "PASS") &&
        !basicInformation.governmentIdIssuedState
      ) {
        errorMessage = REQUIRED_ERROR;
      }
      if (!dropdownLoader && dropdownsData.state.length === 1) {
        errorMessage = API_ERROR_MESSAGE;
      }
      return errorMessage;
    } catch (error: any) {
      console.log("Error in handleStateErrorMessage: ", error?.message);
      return EMPTY_STRING;
    }
  };

  const onAuthCompleted = async (moduleName: string, e: boolean) => {
    try {
      if (moduleName === "RAC Coworker" && e) {
        setShowSecondLevelAuth(false);
        setModuleName(EMPTY_STRING);
        setBasicInformation({
          ...basicInformation,
          racCoworker:
            basicInformation.racCoworker == STRING_YES ? STRING_NO : STRING_YES,
        });
        setUpdatedBasicInfo({
          ...updatedBasicInfo,
          racCoworker:
            updatedBasicInfo.racCoworker == STRING_YES ? STRING_NO : STRING_YES,
        });
        setIsCustomerInfoUpdate(true);
      }
      if (moduleName === "ViewPIIData" && e) {
        setShowCustomerInfo(!showCustomerInfo);
        setShowDecryptedValues(true);
        setShowSecondLevelAuth(false);
        setScreenLoader(false);
      }
    } catch (error: any) {
      console.log(error, 'Error in onAuthCompleted function of Basicinformation')
    }
  };

  const validateAndUpdateCustomer = async () => {
    try {
      setScreenLoader(true);

      const paylaod = formBasicInfoPayload(updatedBasicInfo, customerId);
      const updateCustomer = await UpdateCustomerDetails(paylaod);
      if (updateCustomer?.UpdateCustomer?.value?.customerId) {
        updatedBasicInfo.isEditEnabled = true;
        setBasicInformation(updatedBasicInfo);
        if (getCustomerInfoResponse?.GetCustomer?.value) {
          setGetCustomerInfoResponse({
            ...getCustomerInfoResponse,
            GetCustomer: {
              ...getCustomerInfoResponse.GetCustomer,
              value: {
                ...getCustomerInfoResponse.GetCustomer.value,
                firstName: updatedBasicInfo.firstName,
                lastName: updatedBasicInfo.lastName
              }
            }
          });
        }
      }
      setScreenLoader(false);
    } catch (error: any) {
      setScreenLoader(false);
      console.log("Error in Basic Info Update customer call", error);
    }
  };

  /*** Row Items Html ***/

  const informationRowOne = (): JSX.Element => (
    <Grid container spacing={2} className={classes.mb8}>
      <Grid item md={3}>
        <RACTextbox
          inputlabel="Last Name"
          name={"lastName"}
          id={"lastName"}
          key={"LastNameInput"}
          maxlength={30}
          required={true}
          disabled={agreementTransferInit || basicInformation.isEditEnabled}
          value={
            basicInformation.isEditEnabled
              ? basicInformation.lastName
              : updatedBasicInfo.lastName
          }
          OnChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleCustomerName(event.target.name, event.target.value);
          }}
          errorMessage={
            basicInformation.isEditEnabled ? requiredFieldExists && basicInformation.lastName === EMPTY_STRING
              ? REQUIRED_ERROR
              : EMPTY_STRING : errorMessage.lastName
          }
        />
      </Grid>

      <Grid item md={3}>
        <RACTextbox
          inputlabel="First Name"
          name={"firstName"}
          id={"firstName"}
          key={"FirstNameInput"}
          type={"text"}
          maxlength={30}
          required={true}
          disabled={agreementTransferInit || basicInformation.isEditEnabled}
          value={
            basicInformation?.isEditEnabled
              ? basicInformation.firstName
              : updatedBasicInfo.firstName
          }
          OnChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleCustomerName(event.target.name, event.target.value);
          }}
          errorMessage={
            basicInformation.isEditEnabled ? requiredFieldExists && basicInformation.firstName === EMPTY_STRING
              ? REQUIRED_ERROR
              : EMPTY_STRING : errorMessage.firstName
          }
        />
      </Grid>
      {!legalHoldScreen ?
        <>
          <Grid
            style={{ marginTop: "3px" }}
            id={"dateofbirthContainer"}
            key={"dateofbirthContainer"}
            item
            md={3}
          >
            <MaskableDatePicker
              key={"maskableDatePicker"}
              loading={false}
              isRequired={true}
              isDisable={false || basicInformation.isEditEnabled}
              unmask={showDecryptedValues}
              value={
                basicInformation.isEditEnabled
                  ? showDecryptedValues
                    ? basicInformation.dateOfBirthDec
                    : basicInformation.dateOfBirth
                  : showDecryptedValues
                    ? updatedBasicInfo.dateOfBirthDec
                    : updatedBasicInfo.dateOfBirth
              }
              errorMessage={
                basicInformation.isEditEnabled ? requiredFieldExists && basicInformation.dateOfBirth === EMPTY_STRING
                  ? "Required"
                  : EMPTY_STRING : errorMessage.dateOfBirth
              }
              onChange={(date: string) => {
                saveOnChangeValue("dateOfBirth", date.toString());
              }}
            />
          </Grid>

          <Grid
            item
            md={3}
            key={"basicInfoSsn"}
            id={"basicInfoSsn"}
          >
            <SSNField
              key={"customerSsnField"}
              unmaskSSN={showDecryptedValues}
              value={
                basicInformation.isEditEnabled
                  ? showDecryptedValues
                    ? basicInformation.taxIdDecr
                    : basicInformation.ssn
                  : showDecryptedValues
                    ? updatedBasicInfo.taxIdDecr
                    : updatedBasicInfo.ssn
              }
              disable={
                agreementTransferInit ||
                showDecryptedValues ||
                basicInformation.isEditEnabled
              }
              onChange={(ssnValue: string) => {
                saveOnChangeValue("ssn", ssnValue);
                setisMaskedDataHasChanged((prevState: any) => ({
                  ...prevState,
                  ssn: true,
                }));
              }}
              errorMessage={
                basicInformation.isEditEnabled ? requiredFieldExists &&
                  basicInformation?.ssn?.length != 0 &&
                  basicInformation.ssn?.length < 9
                  ? "Please enter valid SSN Number"
                  : EMPTY_STRING : errorMessage.ssn
              }
            />
          </Grid>
        </> : null}
    </Grid>
  );

  const informationRowTwo = (): JSX.Element => (
    <>
      {isNotCustVerfOpt ? (
        <>
          <Grid item md={3}>
            <RACTextbox
              inputlabel="Email Address"
              name={`emailAddress`}
              id={"emailAddress"}
              key={"EmailInput"}
              type={"text"}
              maxlength={50}
              required={true}
              disabled={agreementTransferInit}
              value={basicInformation.emailAddress}
              Onblur={() => {
                validateEmail();
              }}
              OnChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                saveOnChangeValue("emailAddress", event.target.value?.trim());
              }}
              errorMessage={mailErrorMessage}
            />
          </Grid>

          <Grid item md={3}>
            <RACSelect
              inputLabel="Gov ID Type"
              name="governmentIdType"
              key={"govIdDropdown"}
              loading={dropdownLoader}
              isDisabled={agreementTransferInit || showDecryptedValues}
              options={dropdownsData.govtIDType}
              defaultValue={valueExistsInDropdown(
                dropdownsData.govtIDType,
                basicInformation.governmentIdType
              )}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                handleGovtIdType(event.target.value);
                setisMaskedDataHasChanged((prevState: any) => ({
                  ...prevState,
                  govtId: true,
                }));
              }}
              errorMessage={getGovIdTypeErrorMessage()}
            />
          </Grid>

          <Grid item md={3}>
            <RACTextbox
              inputlabel="Gov ID Number"
              name={`governmentId`}
              id={`governmentId`}
              key={"GovIdInput"}
              type={"text"}
              maxlength={30}
              disabled={disableGovtIdNumber()}
              value={getGovIdNumberValue()}
              OnChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleGovtId(event.target.value);
              }}
              onFocus={() => {
                setGovIdFocused(true);
              }}
              Onblur={() => {
                setGovIdFocused(false);
              }}
            />
          </Grid>

          <Grid item md={3}>
            <RACSelect
              inputLabel={"State"}
              name={"governmentIdIssuedState"}
              key={"governmentIdIssuedState"}
              required={handleStateRequired(basicInformation.governmentIdIssuedState)}
              loading={dropdownLoader}
              isDisabled={handleStateDisable()}
              options={dropdownsData.state}
              defaultValue={valueExistsInDropdown(
                dropdownsData.state,
                basicInformation.governmentIdIssuedState
              )}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                saveOnChangeValue("governmentIdIssuedState", event.target.value);
              }}
              errorMessage={handleStateErrorMessage()}
            />
          </Grid>
        </>
      ) : null}
    </>
  );

  const informationRowThree = (): JSX.Element => (
    <>
      <Grid item md={3}>
        <RACSelect
          inputLabel={"Salutation"}
          name={"salutation"}
          key={"salutation"}
          loading={dropdownLoader}
          isDisabled={agreementTransferInit || basicInformation.isEditEnabled}
          options={dropdownsData.salutation}
          defaultValue={valueExistsInDropdown(
            dropdownsData.salutation,
            basicInformation.isEditEnabled
              ? basicInformation.salutation
              : updatedBasicInfo.salutation
          )}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            saveOnChangeValue("salutation", event.target.value);
          }}
          errorMessage={
            !dropdownLoader && dropdownsData.salutation.length == 1
              ? API_ERROR_MESSAGE
              : EMPTY_STRING
          }
        />
      </Grid>

      <Grid item md={3}>
        <RACTextbox
          inputlabel={"Middle initial"}
          name={"middleInitial"}
          key={"middleInitial"}
          id="middleInitial"
          type={"text"}
          maxlength={1}
          disabled={agreementTransferInit || basicInformation.isEditEnabled}
          value={
            basicInformation.isEditEnabled
              ? basicInformation.middleInitial
              : updatedBasicInfo.middleInitial
          }
          OnChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleMiddleName(event.target.value);
          }}
        />
      </Grid>

      <Grid item md={3}>
        <RACSelect
          inputLabel={"Jr/Sr"}
          name={"suffix"}
          key={"suffix"}
          loading={dropdownLoader}
          isDisabled={agreementTransferInit || basicInformation.isEditEnabled}
          options={dropdownsData.suffix}
          defaultValue={valueExistsInDropdown(
            dropdownsData.suffix,
            basicInformation.isEditEnabled
              ? basicInformation.suffix
              : updatedBasicInfo.suffix
          )}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            saveOnChangeValue("suffix", event.target.value);
          }}
          errorMessage={
            !dropdownLoader && dropdownsData.suffix.length == 1
              ? API_ERROR_MESSAGE
              : EMPTY_STRING
          }
        />
      </Grid>

      <Grid
        item
        md={3}
        style={{ padding: "32px 13px" }}
      >
        <RACToggle
          name="racCoworker"
          id="racCoworker"
          leftText={true}
          leftLabel={"RAC Co-worker"}
          disabled={agreementTransferInit || basicInformation.isEditEnabled}
          checked={basicInformation.racCoworker === STRING_NO ? false : true}
          OnChange={(e: any) => {
            handleRACCoworker();
          }}
          GridClassName={classes.toggleGrid}
          labelGridClassName={classes.toggleLabelGrid}
          labelClassName={classes.toggleLabel}
          toggleColor={RACCOLOR.NORMAL_BLUE}
        />
      </Grid>
    </>
  );

  const informationRowFour = (): JSX.Element => (
    <Grid container md={12}>
      {isNotCustVerfOpt ? (
        <>
          <Grid item md={3}>
            <Grid
              title={
                basicInformation.oktaId
                  ? "This account is already linked with e-pay login"
                  : undefined
              }
            >
              <RACButton
                variant="contained"
                color="primary"
                id="webLink"
                disabled={basicInformation.oktaId !== EMPTY_STRING}
                onClick={() => {
                  handleWebLink({
                    setShowPopup,
                    setPopupType,
                    setPopupMessage,
                    setScreenLoader,
                    basicInformation,
                    customerId,
                  });
                }}
              >
                Web Link
              </RACButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            md={2}
            style={
              isNotCustVerfOpt
                ? { padding: "18px 40px" }
                : { padding: "10px 0px" }
            }
          >
            <RACToggle
              name="personalInformation"
              id="personalInformation"
              rightText={true}
              rightLabel={"Show Personal Information"}
              disabled={agreementTransferInit}
              checked={showCustomerInfo}
              OnChange={() => {
                handleShowPersonalInfo();
              }}
              GridClassName={classes.toggleGrid}
              labelGridClassName={classes.toggleLabelGrid}
              labelClassName={classes.toggleLabel}
              toggleColor={RACCOLOR.NORMAL_BLUE}
            />
          </Grid>
          <Grid
            item
            md={10}
            className={`${classes.textAlignRight}`}
          >
            {!basicInformation.isEditEnabled ? (
              <>
                <RACButton
                  variant="outlined"
                  color="primary"
                  id="cancel"
                  className={`${classes.buttonsPadding} ${classes.mr16}`}
                  onClick={() => {
                    updateObjectStateVariable(
                      setBasicInformation,
                      "isEditEnabled",
                      true
                    );
                    setUpdatedBasicInfo(basicInformation);
                  }}
                >
                  Cancel
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  id="save"
                  disabled={!saveButtonDisable}
                  className={`${classes.buttonsPadding} ${classes.mr25p}`}
                  onClick={() => {
                    if (validateFields()) validateAndUpdateCustomer();
                  }}
                >
                  Save
                </RACButton>
              </>
            ) : (
              <RACButton
                variant="contained"
                color="primary"
                id="edit"
                className={`${classes.buttonsPadding} ${classes.mr25p}`}
                onClick={() => {
                  updateObjectStateVariable(
                    setBasicInformation,
                    "isEditEnabled",
                    false
                  );
                }}
              >
                Edit
              </RACButton>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <Fragment key={"BasicInfoComponent"}>
      {/*** Popups ***/}

      {showPopup ? (
        <DynamicAlertPopup
          key={"AlertComponent"}
          closeIcon={true}
          alertMessage={popupMessage}
          alertType={popupType}
          onCloseFn={() => {
            setShowPopup(false);
          }}
        />
      ) : null}

      {showSecondLevlAuth ? (
        <SecondFactor
          key={"SLAComponent"}
          setTwoFactorCancelClick={() => {
            setShowSecondLevelAuth(false);
            showCustomerInfo ? setShowCustomerInfo(false) : null;
          }}
          setTwoFactorCompleted={(e: any) => onAuthCompleted(moduleName, e)}
          moduleName={moduleName}
          currentRole={currentRole}
        />
      ) : null}

      {/* Main Component */}

      <Grid container key={"BasicInfoContainer"}>
        <Grid
          item
          key={"bsicInfoHeaderComponent"}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Typography
            className={`${classes.title}`}
            key={"BasicTitleContainer"}
            id={"BasicTitleContainer"}
          >
            Basic Information
          </Typography>
        </Grid>

        <Grid
          item
          key={"InfoCardHolder"}
          id={"InfoCardHolder"}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Card
            key={"BasicInfoCard"}
            id={"BasicInfoCard"}
            className={`${classes.card}`}
          >
            <CardContent
              key={"BasicInfoCardContect"}
              id={"BasicInfoCardContect"}
              className={`${classes.mb8}`}
            >
              {informationRowOne()}
              {!legalHoldScreen ? <Grid container spacing={2}>
                {informationRowTwo()}

                {informationRowThree()}

                {informationRowFour()}
              </Grid> : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export const handleWebLink = async (request: any): Promise<void> => {
  try {
    const webLinkPayload: any = {
      customerId: request.customerId,
      globalCustomerId: request.customerBasicInformation.globalCustomerId,
      emailAddress: request.customerBasicInformation.emailAddress,
      linkOnlineAccount: STRING_YES,
    };
    request.setScreenLoader(true);
    const webLinkStatus: string = await linkCustomerInfoInWeb(webLinkPayload);
    request.setScreenLoader(false);

    if (webLinkStatus === "NotExists") {
      request.setPopupMessage(`Web Link did not occur. No e-pay email account found .
        Have Customer create account online.`);
      request.setPopupType("");
      request.setShowPopup(true);
    } else if (webLinkStatus === "Success") {
      request.setPopupMessage(
        `Email address successfully linked with online account.`
      );
      request.setPopupType("SUCCESS");
      request.setShowPopup(true);
    } else if (webLinkStatus === "Failed") {
      request.setPopupMessage(
        `Web Link did not occur. Please contact RACPad support or solution center`
      );
      request.setPopupType(EMPTY_STRING);
      request.setShowPopup(true);
    } else {
      request.setPopupMessage(`Something went wrong`);
      request.setPopupType("ERROR");
      request.setShowPopup(true);
    }
  } catch (error: any) {
    console.log("Error in handleWebLink: ", error?.message);
  }
};