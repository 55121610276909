/* eslint-disable */
/* eslint-disable no-console */
import React from "react";
import {
  DaysPaidDropDownValue,
  EmployerDetails,
  ReferenceDetail,
  ResidenceInfo,
  UpdateCustomer,
} from "../../interface/globalInterface";
import moment from "moment";
import {
  UpdateCustomerDetails,
  UpdateVerificationOptimization,
} from "./../../../api/user";
import { CustomerBasicInformation, ReferenceInformation } from "../../interface/updateCustomerInterface";
import { EMPTY_STRING, INVALID_EMAIL_FORMAT, MAIL_REGEX, REQUIRED_ERROR, SSN } from "../../constants/constants";
import { GetCustomer, GetMenuAndStoreBasedOnProfile } from "../../api/user";

const currentDate: string = moment().format("YYYY-MM-DD");
export const updateEmploymentDetails = (
  index: number,
  employerList: EmployerDetails[],
  setterData: React.Dispatch<React.SetStateAction<EmployerDetails[]>>,
  updatedFields: Partial<EmployerDetails>
) => {
  console.log("Request Props", index, employerList, updatedFields);
  const updatedList = [...employerList];
  updatedList[index] = {
    ...updatedList[index],
    ...updatedFields,
    basicInfo: {
      ...updatedList[index].basicInfo,
      ...updatedFields.basicInfo,
    },
    incomeVerification: {
      ...updatedList[index].incomeVerification,
      ...updatedFields.incomeVerification,
    },
    addressInfo: {
      ...updatedList[index].addressInfo,
      ...updatedFields.addressInfo,
    },
  };
  setterData(updatedList); // Update the state with the modified array
};

export const formatText = (value: any): string => {
  return value && value.trim().length == 0
    ? value.trim().replace(/[^A-Z'.;’ a-z]/gi, "")
    : value.replace(/[^A-Z'.;’ a-z]/gi, "");
};

export const formatNumber = (value: any): number => {
  return value && value.trim().length ? value.trim() : "";
};

export const formatMobileNumber = (value: string | undefined): string => {
  const phoneFormat = "($1) $2-$3";
  const cleaned = ("" + value).replace(/\D/g, "");
  //This is raw data to pass on props
  const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return cleaned.replace(PhoneRegex, phoneFormat);
};

// Function to update a single ResidenceInfo object
export const updateResidenceInfo = (
  currentData: ResidenceInfo,
  setterData: React.Dispatch<React.SetStateAction<ResidenceInfo>>,
  updatedFields: Partial<ResidenceInfo>
) => {
  console.log("Updated fields", currentData, updatedFields);
  const updatedResidenceInfo: ResidenceInfo = {
    ...currentData,
    ...updatedFields,
    rentalDetails: {
      ...currentData.rentalDetails,
      ...updatedFields.rentalDetails,
    },
    ownResidenceDetails: {
      ...currentData.ownResidenceDetails,
      ...updatedFields.ownResidenceDetails,
    },
  };
  setterData(updatedResidenceInfo);
};

export const updateObjectStateVariable = <T,>(
  setState: React.Dispatch<React.SetStateAction<T>>,
  key: string,
  value: any
) => {
  setState((prevState) => ({
    ...prevState,
    [key]: value,
  }));
};

export const updateReferenceInfo = (
  index: number | undefined,
  referenceInfo: ReferenceInformation[],
  setterData: React.Dispatch<React.SetStateAction<ReferenceInformation[]>>,
  updatedFields: Partial<ReferenceInformation>
) => {
  console.log("Request Props", index, referenceInfo, updatedFields);
  index = index ? index : 0;
  const updatedList = [...referenceInfo];
  updatedList[index] = {
    ...updatedList[index],
    ...updatedFields,
    referenceInfo: {
      ...updatedList[index].referenceInfo,
      ...updatedFields.referenceInfo,
    },
    referenceAddressInfo: {
      ...updatedList[index].referenceAddressInfo,
      ...updatedFields.referenceAddressInfo,
    },
  };
  setterData(updatedList);
};

export const formatZipCode = (value: string | undefined) => {
  const cleaned = ("" + value).replace(/\D/g, "");
  return cleaned.length > 5
    ? cleaned.slice(0, 5) + "-" + cleaned.slice(5)
    : cleaned;
};

// Generic function to update array of objects
export const updateArrayOfObjectState = (
  stateVariable: any,
  setterFunction: any,
  index: any,
  updateFields: any
) => {
  console.log('Props in UpdateArray', stateVariable, updateFields);
  const updatedState = [...stateVariable];
  updatedState[index] = {
    ...updatedState[index],
    ...updateFields,
  };
  setterFunction(updatedState);
};

//Null Variable Checker
export const isNotNullVariable = (
  value: string | null | undefined | number
): boolean => {
  return value && value !== "null" && value !== "undefined" ? true : false;
};

export const isNonEmptyArray = <T,>(value: T[]): boolean => {
  return Array.isArray(value) && value.length > 0;
};

export const isDropDownValueExist = (
  options: { value?: string }[],
  value: string
): boolean => {
  return options.some((option) => option.value === value);
};

export const validateEmploymentInfo = (data: EmployerDetails) => {
  console.log("data", data);
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  return (
    !isNotNullVariable(data.basicInfo.companyName) ||
    !isNotNullVariable(data.basicInfo.phoneNumber) ||
    data.basicInfo.phoneNumber.length < 10 ||
    !isNotNullVariable(data.incomeVerification.paydayFrequency) ||
    data.incomeVerification.paydayFrequency === "0" ||
    (data.incomeVerification.paydayFrequency &&
      data.incomeVerification.paydayFrequency !== "0" &&
      data.incomeVerification.paydayFrequency !== "DAILY" &&
      !(
        data.incomeVerification.dayPaid &&
        data.incomeVerification.dayPaid !== "0"
      ))
  );
};

export const getDaysPaidDropDown = (
  paydayFrequency: string,
  dropdownOptions: DaysPaidDropDownValue
) => {
  console.log('dropdownOptions', dropdownOptions);
  switch (paydayFrequency) {
    case "SEMI":
      return dropdownOptions.semiMonthly;
    case "WK":
      return dropdownOptions.weekly;
    case "BIWK":
      return dropdownOptions.weekly;
    case "MON":
      return dropdownOptions.monthly;
    default:
      return [];
  }
};

export const removeObjectFromArray = <T,>(
  datas: T[],
  index: number,
  setterFunction: React.Dispatch<React.SetStateAction<T[]>>
) => {
  setterFunction([...datas.slice(0, index), ...datas.slice(index + 1)]);
};

export const verifiedResidenceDisable = (
  residences: ResidenceInfo
): boolean => {
  const { rentalType, ownResidenceDetails, rentalDetails } = residences;

  if (rentalType === "RENT") {
    return !(
      isNotNullVariable(rentalDetails.landlordsFirstName) &&
      isNotNullVariable(rentalDetails.landlordsLastName) &&
      isNotNullVariable(rentalDetails.landlordsPhoneNumber) &&
      rentalDetails.landlordsPhoneNumber.length >= 10
    );
  } else if (rentalType === "OWN" && residences.mortgage) {
    return !isNotNullVariable(ownResidenceDetails.mortgageCompanyName);
  } else {
    return true;
  }
};

// export const verifiedRefernceDisable = (data: Partial<ReferenceInformation>): boolean => {
//   const { relativeFirstName, relativeLastName, phoneNumber, relationShip } =
//     data.referenceInfo;

//   if (!isNotNullVariable(relativeFirstName)) return true;
//   if (!isNotNullVariable(relativeLastName)) return true;
//   if (!isNotNullVariable(phoneNumber) || phoneNumber.length < 10) return true;
//   if (!isNotNullVariable(relationShip) || relationShip === "0") return true;

//   return false;
// };

export const updateCustomerAndVerification = (
  updateObject: any,
  verifiedTab: string,
  customerId: string,
  setGetCustomerInfoResponse: any,
  setGetApprovalRes: any,
  actualDocumentDetail?: any,
  setIntellicheckStatus?: any

): Promise<boolean> => {
  let updateCustomerPaylaod: any = {};
  let updateApprovalPayload: any = {};
  console.log("Udpated Customer Payload", updateObject, updateObject?.verified);
  const verified = updateObject && updateObject?.verified ? "Y" : "N";
  const verifiedDate =
    updateObject && updateObject.verified ? currentDate : undefined;

  switch (verifiedTab) {
    case "ID":
      updateCustomerPaylaod = {
        customerId: customerId,
        governmentIdType: updateObject.governmentIdType,
        governmentId: updateObject?.governmentId?.includes('*') ? updateObject?.govtIdDecrpted?.replaceAll('*', '') : updateObject.governmentId,
        governmentIdIssuedState: updateObject.issuedState,
      };
      updateApprovalPayload = updateObject?.decisionEngineId ? {
        decisionId: updateObject.decisionEngineId,
        isManageApproval: true,
        verifyCode: updateObject.verifyCode,
        verificationDocuments: [
          {
            verificationStatus: actualDocumentDetail?.verificationStatus == 'PEND' && verified == 'Y' ? 'VERIFIED' : actualDocumentDetail?.verificationStatus == 'PEND' && verified == 'N' ? 'PEND' : actualDocumentDetail?.verificationStatus == 'PENDREW' && verified == 'Y' ? 'VERIFIED' : actualDocumentDetail?.verificationStatus == 'PENDREW' && verified == 'N' ? 'PENDREW' : null,
            verified: verified,
            verifyType: "ID",
            modifiedBy: updateObject?.modifiedBy
          },
        ],
      } : {};

      updateApprovalPayload?.verificationDocuments[0]?.verified == true ? setIntellicheckStatus('Passed') : setIntellicheckStatus('');
      break;
    case "EMP":
      updateApprovalPayload = updateObject[0].decisionEngineId ? {
        decisionId: updateObject[0].decisionEngineId,
        isManageApproval: true,
        verifyCode: updateObject[0].verifyCode,
        verificationDocuments: [
          {
            verified: updateObject[0]?.verified ? 'Y' : 'N',
            verifyType: "EMPLOYMENT",
            clientId: "RACPad",
            override: updateObject[0].override ? "Y" : "N",
            modifiedBy: updateObject[0]?.modifiedBy
          },
        ],
      } : {};
      delete updateObject[0]?.decisionEngineId;
      delete updateObject[0]?.verifyCode;
      delete updateObject[0]?.override;
      delete updateObject[0]?.verified;
      delete updateObject[0]?.modifiedBy;
      updateCustomerPaylaod = {
        customerId: customerId,
        employerReferences: updateObject,
      };
      break;
    case "RESI":
      updateCustomerPaylaod = {
        customerId: customerId,
        ...(updateObject.rentalType == 'RENT' && {
          landlordReferences: [{
            landlordFirstName:
              updateObject?.rentalType == 'RENT'
                ? updateObject?.rentalDetails?.landlordsFirstName
                : null,
            landlordLastName:
              updateObject?.rentalType == 'RENT'
                ? updateObject?.rentalDetails?.landlordsLastName
                : null,
            phoneNumber:
              updateObject.rentalType == 'RENT'
                ? updateObject?.rentalDetails?.landlordsPhoneNumber?.replace(/[()\s-]/g, '')
                : null,
            monthlyRent:
              updateObject?.rentalDetails?.paymentAmount &&
                updateObject?.rentalType == 'RENT'
                ? updateObject?.rentalDetails?.paymentAmount.replace(',', '').toString()
                : '0.00',
            moveInDate:
              updateObject?.rentalDetails?.moveInDate &&
                updateObject?.rentalType == 'RENT'
                ? updateObject?.rentalDetails?.moveInDate
                : null,
            active: 'Y',
            leaseLengthYears: "0.000000",
            leaseLengthMonths: "0.000000",
            leaseTerm: null,
            leaseCompanyName: null,
            phoneExtension: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            state: null,
            postalCode: "",
            leaseHolderName: null,
            bestTimeToCall: null,
            verifiedDate: ""
          }]
        }),


        mortgageCompanyName:
          updateObject?.rentalType === 'OWN' &&
            updateObject?.mortgage &&
            updateObject?.ownResidenceDetails?.mortgageCompanyName
            ? updateObject?.ownResidenceDetails?.mortgageCompanyName
            : null,
        monthlyMortgagePayment:
          updateObject?.rentalType == 'OWN' &&
            updateObject?.mortgage &&
            updateObject?.ownResidenceDetails?.paymentAmount
            ? updateObject?.ownResidenceDetails?.paymentAmount.replace(',', '').toString()
            : null,
        residenceSince:
          updateObject?.rentalType === 'OWN' &&
            updateObject?.ownResidenceDetails?.moveInDate
            ? updateObject?.ownResidenceDetails?.moveInDate
            : null,
        residenceType: updateObject?.rentalType == 'RENT' ? 'RENT' : 'OWN',
      };
      updateObject?.decisionId ?
        updateApprovalPayload = {
          decisionId: updateObject?.decisionId ? updateObject?.decisionId : '',
          isManageApproval: true,
          verifyCode: updateObject?.verifyCode ? updateObject?.verifyCode : '',
          verificationDocuments: [
            {
              verified: updateObject?.residenceVerificationToggle ? 'Y' : 'N',
              verifyType: "RESIDENCE",
              clientId: "RACPad",
              modifiedBy: updateObject?.modifiedBy
            },
          ],
        } : updateApprovalPayload = {}
      break;
    case "REF":
      updateApprovalPayload = updateObject[0]?.decisionId ? {
        decisionId: updateObject[0]?.decisionId ? updateObject[0]?.decisionId : '',
        isManageApproval: true,
        verifyCode: updateObject[0]?.verifyCode ? updateObject[0]?.verifyCode : '',
        verificationDocuments: [
          {
            verified: updateObject[0].verified ? 'Y' : 'N',
            verifyType: "REFERENCES",
            modifiedBy: updateObject[0]?.modifiedBy
          },
        ],
      } : updateApprovalPayload = {};
      delete updateObject[0]?.decisionId;
      delete updateObject[0]?.verifyCode;
      delete updateObject[0]?.modifiedBy;
      delete updateObject[0]?.verified;
      updateCustomerPaylaod = {
        customerId: customerId,
        personalReferences: updateObject
      };
      break;
    default:
      break;
  }

  return updateCustomerDetails(updateCustomerPaylaod, updateApprovalPayload, setGetCustomerInfoResponse, setGetApprovalRes);
};

export const updateCustomerDetails = async (
  updateCustomerPaylaod: Partial<UpdateCustomer>,
  updateApprovalPayload?: any,
  setGetCustomerInfoResponse?: any,
  setGetApprovalRes?: any,

): Promise<boolean> => {
  try {
    console.log('Inside the Update Customer Payload', updateApprovalPayload);
    if (updateApprovalPayload && updateApprovalPayload?.decisionId) {
      const manageApprovalResp = await UpdateVerificationOptimization(
        updateApprovalPayload
      );
      console.log('manageApprovalResp', manageApprovalResp);
      if (manageApprovalResp) {
        // const getApprovalResponse = await GetApproval()
        console.log('manageApprovalResp', manageApprovalResp.decisionId)
      }
    }
    const updateCustomer = await UpdateCustomerDetails(updateCustomerPaylaod);
    if (setGetCustomerInfoResponse && setGetApprovalRes && updateApprovalPayload && updateApprovalPayload?.decisionId) {
      const getCustomerResponse = await GetCustomer(updateCustomerPaylaod.customerId || '', window.sessionStorage.getItem('storeNumber') || '');
      console.log('Inside the SetGetCustomerInfo', getCustomerResponse);
      setGetCustomerInfoResponse(getCustomerResponse?.data);
      setGetApprovalRes(getCustomerResponse?.data?.GetApproval?.value?.customerApprovalDetails);
    }
    console.log("updateCustomer", updateCustomer);
    return true;
  } catch (error: any) {
    console.log("Error In Updating Customer in Identity Tab", error);
    return false;
  }
};

// For formatting the text
export const formatTextChange = (value: any) => {
  return value && value.trim().length > 0 ? value.trim() : "";
};

/**
 * This function formates the date from MM/DD/YYYY to YYYY-MM-DD
 */
export const formatDateOfBirth = (date: string) => {
  console.log('fomateDateofBirth', date);
  const formatdate = moment(date).format('MM/DD/YYYY');
  const [month, day, year] = formatdate.split("/");
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

export const formBasicInfoPayload = (
  basicInfo: CustomerBasicInformation,
  customerId: string
) => {
  return {
    customerId: customerId,
    firstName: basicInfo.firstName ? basicInfo.firstName : undefined,
    lastName: basicInfo.lastName ? basicInfo.lastName : undefined,
    middleInitial: basicInfo.middleInitial
      ? basicInfo.middleInitial
      : undefined,
    coworker: basicInfo.racCoworker ? basicInfo.racCoworker : undefined,
    suffix: basicInfo.suffix ? basicInfo.suffix : undefined,
    salutation: basicInfo.salutation ? basicInfo.salutation : undefined,
    dateOfBirth: basicInfo.dateOfBirth
      ? formatDateOfBirth(basicInfo.dateOfBirth)
      : undefined,
    taxId: basicInfo.ssn ? basicInfo.ssn : undefined,
    taxIdType: basicInfo.ssn ? SSN : undefined,
  };
};

export const validateEmail = (emailId: string, errorSetterFunc: React.Dispatch<React.SetStateAction<string>>): void => {
  try {
    if (emailId.trim() === EMPTY_STRING) {
      errorSetterFunc(REQUIRED_ERROR);
    } else if (!MAIL_REGEX.test(emailId)) {
      errorSetterFunc(INVALID_EMAIL_FORMAT);
    } else {
      errorSetterFunc(EMPTY_STRING);
    }
  } catch (error: any) {
    console.log("Error in validateEmail: ", error?.message);
  }
};

export const updateObjectWithMultipleKeys = <T,>(setStateFunction: React.Dispatch<React.SetStateAction<T>>, updates: Partial<Record<keyof T, any>>) => {
  setStateFunction(prevState => ({
    ...prevState,
    ...updates
  }));
};

export const getCoworkerNames = async (
  setterFunc: any,
  coWorkerId: any,
  setCurrentRole: any,
  GetApprovalResp: any
) => {
  try {
    console.log("GetApprovalRespUtils", GetApprovalResp);
    const approvalIds: string[] = coWorkerId ? [coWorkerId] : [];
    GetApprovalResp?.verificationDocuments?.forEach((element: any) => {
      element?.lastModifiedBy && !approvalIds.includes(element.lastModifiedBy)
        ? approvalIds.push(element.lastModifiedBy)
        : null;
    });

    const getCoworkerNamesFunc = approvalIds.map((element: any) => {
      if (element != 'Intellicheck') {
        return GetMenuAndStoreBasedOnProfile({
          coworkerId: element,
          storesRequired: false,
          menuRequired: false,
        })
      }
    }

    );

    console.log("getCoworkerNamesFunc", getCoworkerNamesFunc);

    const coworkerRes = await Promise.all(getCoworkerNamesFunc);

    console.log("coworkerRes", coworkerRes);
    const coWorkerName: any = {
      'Intellicheck': 'Intellicheck'
    };
    coworkerRes.forEach((element: any) => {
      if (
        element?.status === 200 &&
        element?.data?.coworkerProfile?.firstName &&
        element?.data?.coworkerProfile?.lastName
      ) {
        const { employeeId, firstName, lastName, role } =
          element.data.coworkerProfile;
        coWorkerName[employeeId] = `${firstName} ${lastName}`;
        if (employeeId == coWorkerId) {
          console.log('Inside me', role);
          setCurrentRole(role);
        }
      } else {
        console.log("Error in coworkerRes Api:", element);
      }
    });
    console.log("coWorkerNames", coWorkerName);
    setterFunc(coWorkerName);
  } catch (error: any) {
    console.log("Error in getCoworkerNames function:", error);
  }
};
